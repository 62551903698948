@import '../../styles/utils';

.wrapper {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color(light);
}

.spinner {
  font-size: 4px;
  border-color: rgba(color(primary), 0.4);
  border-left-color: color(primary);
}
