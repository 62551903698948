@import '../../styles/utils';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100vh;
  background-color: color(light);
}

.content {
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.info {
  margin-top: 28px;
}

.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
}

.description {
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  line-height: 22px;
  max-width: 442px;
}

.logo {
  max-width: 170px;
  margin-left: auto;
  margin-right: auto;

  img {
    display: block;
  }
}

.logoImage {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  margin-bottom: 32px;
}

.feature {
  &:not(:last-child) .featureImage::before {
    content: url('/assets/images/home/arrow.svg');
    position: absolute;
    @include size(130px);

    @include screen(md) {
      display: none;
    }
  }

  &:first-child {
    margin-top: -80px;

    @include screen(md) {
      margin-top: 0;
    }

    .featureImage::before {
      bottom: -50px;
      right: -110px;
    }

    .featureInfo {
      margin-top: 50px;

      @include screen(md) {
        margin-top: 30px;
      }
    }
  }

  &:nth-child(2) {
    margin-right: -40px;

    @include screen(md) {
      margin-right: 0;
    }

    .featureImage::before {
      transform: rotate(-53deg);
      top: -36px;
      right: -100px;
    }

    .featureInfo {
      margin-top: 38px;

      @include screen(md) {
        margin-top: 30px;
      }
    }
  }

  &:last-child {
    margin-top: -80px;

    @include screen(md) {
      margin-top: 0;
    }

    .featureInfo {
      margin-top: 43px;

      @include screen(md) {
        margin-top: 30px;
      }
    }
  }
}

.features {
  display: flex;
  justify-content: space-between;
  margin-top: 112px;

  @include screen(md) {
    display: block;
    margin-top: 60px;

    > *:not(:first-child) {
      margin-top: 60px;
    }
  }
}

.featureImage {
  position: relative;

  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.featureTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  color: color(primary);
}

.featureDescription {
  margin-top: 18px;
  font-size: 15px;
  line-height: 22px;
}

.button {
  width: 100%;

  img {
    width: 90%;
  }
}

.buttons {
  margin-top: 46px;
  display: flex;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;

  @include screen(xs) {
    display: block;
    margin-top: 60px;
    max-width: 180px;
  }

  > *:not(:first-child) {
    margin-left: 26px;

    @include screen(xs) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

.note {
  margin-top: 32px;
  font-size: 15px;
  line-height: 22px;
  color: color(gray-400);
}
