@import "../../styles/utils";

.wrapper {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  line-height: 24px;
}

.checkbox {
  position: relative;
  display: inline-block;
  min-width: 16px;
  min-height: 16px;
  border: 2px color(primary) solid;
  border-radius: radius(xs);
  margin-top: 4px;
  margin-right: 8px;

  &--active {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: radius(xxs);
      background-color: color(primary);
      transform: scale(0.75);
    }
  }
}
