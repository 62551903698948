@import '../../styles/utils';

.label {
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 14px;
  color: color(gray);
}

.icon {
  position: absolute;
  top: 50%;
  left: 0;
  @include size(24px);
  transform: translateY(-50%);
}

.valueWrapper {
  position: relative;
}

.value {
  line-height: 22px;
  @include ellipsis(100%);
}

.valueWrapperWithIcon {
  padding-left: 45px;

  @include screen(md) {
    padding-left: 44px;
  }
}
