@import '../../styles/utils';

.label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;

  > div {
    width: 100%;
  }

  > span {
    display: block;
    margin-bottom: 5px;
  }
}

.input {
  font-size: 14px;
  line-height: 22px;
  height: 50px;
  width: 100%;
  padding: 14px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: radius(sm);
  background-color: color(light-gray);
  color: color(dark);

  &::placeholder {
    color: color(gray);
  }
}

.error {
  margin-top: 5px;
  font-weight: 400;
  color: color(error);
}
