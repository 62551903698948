@import '../../styles/utils';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 537px;
  margin: 20px auto;
  box-shadow: 0 30px 60px rgba(color(black), 0.09);
  background-color: color(light);

  @include screen(md) {
    max-width: 406px;
  }

  @include screen(xs) {
    margin: auto;
    overflow: auto;
    max-width: 100%;
    height: 100%;
  }
}

.position {
  text-transform: capitalize;
}

.preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 20px;
  background-color: color(primary);
  background-image: url('/assets/images/profile/profile-mask.png');
  background-position: 50% 50%;
  background-size: cover;

  @include screen(md) {
    padding: 40px 20px;
  }
}

.content {
  height: 100%;
  padding: 41px 40px 80px;

  @include screen(md) {
    padding: 31px 40px 40px;
  }

  @include screen(xs) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 41px 24px 40px;
  }
}

.contentTop {
  position: relative;

  @include screen(xs) {
    padding-right: 90px;
  }
}

.logoWrapper {
  position: absolute;
  bottom: -42px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(100px);
  border-radius: radius(xl);
  background-color: color(light);
  box-shadow: 0 5.72346px 42.9259px rgba(color(black), 0.07);

  @include screen(md) {
    bottom: -48px;
    @include size(78px);
  }

  @include screen(xs) {
    top: 0;
    right: 0;
    bottom: auto;
    @include size(70px);
  }

  > * {
    position: relative !important;
    @include size(78%);
  }
}

.title {
  font-size: 28px;
  font-weight: 500;
  line-height: 45px;

  @include screen(md) {
    font-size: 24px;
  }

  @include screen(xs) {
    line-height: 32px;
  }
}

.button {
  width: 100%;
  margin-top: 89px;
}

.mainFields {
  margin-top: 20px;

  @include screen(md) {
    margin-top: 10px;
  }

  @include screen(xs) {
    margin-top: 20px;
  }

  & > * {
    margin-top: 15px;

    @include screen(md) {
      margin-top: 14px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.otherFields {
  margin-top: 40px;

  @include screen(md) {
    margin-top: 16px;
  }

  @include screen(xs) {
    margin-top: 35px;
  }

  & > * {
    margin-top: 15px;

    @include screen(md) {
      margin-top: 13px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
