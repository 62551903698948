.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.description {
  margin-top: 30px;
  font-size: 14px;
  line-height: 22px;
}

.button {
  width: 100%;
  margin-right: 13px;

  img {
    max-width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.buttons {
  margin-top: 40px;
  display: flex;
}
