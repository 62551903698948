@import '../../styles/utils';

.wrapper {
  position: relative;
  @include size(199px);
  border-radius: 50%;
  background-color: color(light);
  overflow: hidden;

  @include screen(md) {
    @include size(152px);
  }

  @include screen(xs) {
    @include size(139px);
  }

  img {
    max-width: 100%;
    display: block;
  }
}

.wrapperCover {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    @include size(53.5%);
  }
}
