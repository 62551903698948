@import '../../styles/utils';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100vh;
  background-color: color(light);
}

.content {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.image {
  max-width: 272px;
  margin: 33px auto 97px;

  img {
    max-width: 100%;
    display: block;
  }
}

.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
}

.message {
  line-height: 22px;

  a {
    font-weight: 500;
    display: inline-block;
    color: color(primary);
  }
}
