*,
*::before,
*::after {
  box-sizing: border-box;
}

/* ##########
Убираем outline
########## */

button::-moz-focus-inner {
  border: 0;
}

*:focus {
  outline: none;
}

/* ##########
Цвет выделения текста
########## */

::selection {
  background: color(primary);
  color: white;
}

/* ##########
Стандартный цвет плейсхолдера
########## */

input,
textarea {
  &::placeholder {
    color: color(gray);
  }
}

/* ##########
Настройки для автозаполнения Chrome
########## */

input:-webkit-autofill {
  -webkit-text-fill-color: color(
    input
  ) !important; // Поменять цвет на аналогичный цвету input
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000000000s ease-in-out 0s !important; // Убираем желтую заливку для автозаполнения в Chrome
}

/* ##########
Основные стили
########## */

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; // Убираем горизонтальный скролл
  width: 100%;
  min-height: 100%;
}

body {
  font-family: $font-primary; // Задаем основной шрифт
  font-size: 16px; // Убираем проблему с отступами между inline-элементами
  color: color(dark);
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

img {
  box-sizing: content-box;
}

/* ##########
Сброс стандартных отступов для типографики, списков и таблиц
########## */

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
span,
ul,
ol,
li,
tr,
td,
th,
table,
blockquote {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  color: inherit;
  font-weight: inherit;
}

ul,
ol {
  list-style: none;
}

span {
  font-style: inherit;
  font-family: inherit;
  font-weight: inherit;
}

a {
  display: block;
  text-decoration: none;
  cursor: pointer;
}

/* ##########
Сброс стилей для активных элементов, добавляем стандарнтый transition
########## */

a,
input,
button,
select,
textarea {
  transition: color $ease-main, border-color $ease-main;
}

button {
  cursor: pointer;
}

select,
input,
textarea,
button {
  color: color(light);
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0; // Убираем авто-закругление углов на iOS
  background-color: transparent;
  font-family: $font-primary;
}

input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
