@import '../../styles/utils';

.main {
  width: 100vw;
  height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  @include size(100%);
  background-color: color(dark);
  background-position: 50% 50%;
  background-size: cover;

  :global(.webp) & {
    background-image: url('/assets/images/profile/bg.webp');
  }

  :global(.no-webp) & {
    background-image: url('/assets/images/profile/bg.jpg');
  }
}

.wrapperScrollable {
  justify-content: start;
}
