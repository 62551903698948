@import '../../styles/utils';

.loader {
  font-size: 2px;
  border-color: rgba(color(light), 0.4);
  border-left-color: color(light);
}

.button {
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  text-align: center;
  appearance: none;
  white-space: nowrap;
  background-color: transparent;
  transition: opacity $ease-main, background-color $ease-main, color $ease-main;
  cursor: pointer;

  $this: &;

  &--md {
    height: 45px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 22px;
    border-radius: radius(sm);
  }

  &--lg {
    height: 50px;
    padding: 0 15px;
    font-size: 18px;
    line-height: 24px;
    border-radius: radius(md);
  }

  &--primary {
    border: 0;
    background-color: color(primary);
    color: color(light);
    fill: color(light);

    &:focus,
    &:hover {
      background-color: darken(color(primary), 8%);
    }
  }

  &--dark {
    border: 0;
    background-color: color(dark);
    color: color(light);
    fill: color(light);
  }

  &--loading {
    cursor: not-allowed;
  }

  > div {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;

    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
