/*
Миксин для определения размеров содержимого
Пример использования:
div {
  @include size(100%);
}
*/
@mixin size($size, $type: null) {
  @if ($type == max) {
    max-width: $size;
    max-height: $size;
  } @else if ($type == min) {
    min-width: $size;
    min-height: $size;
  } @else {
    width: $size;
    height: $size;
  }
}

/*
Миксин для элементов, которые должны сохранять определенное соотношение сторон (напр., блок с видео)
Пример использования:
div {
  @include ratio(16,9);
}
*/
@mixin ratio($x, $y, $pseudo: false) {
  $padding: unquote((($y/$x) * 100) + "%");

  @if $pseudo {
    &::before {
      @include pseudo($pos: relative);

      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

/*
Миксин для базовых параметров псевдоэлементов.
Пример использования:

div::after {
  @include pseudo;
  top: 10px;
  left: 10px;
  width: 150px;
}
*/
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

/*
Миксин для треугольников, сделанных через :before и :after
Пример:

div::after {
  @include triangle(black, top, 10px);
}
*/
@mixin triangle(
  $color,
  $direction,
  $size: 6px,
  $position: absolute,
  $round: false
) {
  @include pseudo($pos: $position);

  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

/*
Миксин для медиа-запросов
Пример использования:
div {
  @include screen('phone') {
    // Правила для разрешений выше 'phone'
  }

  @include screen('phone', max) {
    // Правила для разрешений ниже 'phone'
  }
}
*/
@mixin screen($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type == max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  } @else {
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/*
Быстренько подрубаем аппаратное ускорение для анимирующихся элементов.
Пример использования:
div {
  @include performance;
}
*/
@mixin performance($backface: true, $perspective: 1000) {
  @if $backface {
    backface-visibility: hidden;
  }

  perspective: $perspective;
}

/*
Миксин для обрезания длинной строки троеточием.
Пример использования:
p {
  @include ellipsis(100%);
}
*/
@mixin ellipsis($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
Миксин для быстрого добавления clearfix.
Пример использования:
.parent {
  @include clearfix;
}
*/
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

/*
Миксин для того, чтоб скрыть элемент, но при этом он все равно рендерился
и был доступен в DOM.
Пример использования:
div {
  @include visual-hide;
}
*/
@mixin visual-hide() {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
}

/*
Миксин для быстрого выравнивания блока по центру
Пример использования:
div {
  @include center-block;
}
*/
@mixin center-block($direction: null) {
  position: absolute;
  @if ($direction == vertical) {
    top: 50%;
    transform: translateY(-50%);
  } @else if ($direction == horizontal) {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*
Миксин для быстрого выравнивания картинки по центру
Пример использования:
div {
  @include center-image;
}
*/
@mixin center-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

/*
Миксин для изображений.
Нужен, чтобы картинка заполняла другой блок подобно background-size: cover
Пример использования:
div {
  @include fill-img;
}
*/
@mixin fill-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: cover;
  font-family: "object-fit: cover;";
  width: 0;
  min-width: 100%;
  min-height: 100%;
}

/*
Миксин для подключения шрифтов.
Описание в файле _fonts.scss
*/
@mixin font-face($name, $style, $weight, $fontname) {
  $path: "../fonts/";

  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src: url($path + $fontname + ".eot");
    src: url("" + $path + $fontname + ".eot?#iefix") format("embedded-opentype"),
      url("" + $path + $fontname + ".otf") format("opentype"),
      url("" + $path + $fontname + ".woff2") format("woff2"),
      url("" + $path + $fontname + ".woff") format("woff"),
      url("" + $path + $fontname + ".ttf") format("truetype"),
      url("" + $path + $fontname + ".svg#" + $fontname) format("svg");
  }
}

/*
Миксин для адаптивных свойств
@include rule(width, (xxl: 100px, lg: 200px));
@include rule(width, (sm: 500px), min); (для mobile-first)
*/
@mixin rule($name, $pointsAndValues, $direction: max) {
  @each $breakpoint, $value in $pointsAndValues {
    @if ($breakpoint == xxl) and ($direction == max) {
      #{$name}: #{$value};
    } @else {
      @include screen("#{$breakpoint}", $direction) {
        #{$name}: #{$value};
      }
    }
  }
}
