@import '../../styles/utils';

$popupZindex: 1000;

.popup {
  position: fixed;
  z-index: $popupZindex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: 0 20px;
  background-color: rgba(7, 21, 41, 0.4);
}

.wrapper {
  position: relative;
  z-index: $popupZindex + 1;
  max-width: 331px;
  padding: 41px 16px;
  margin: 20px auto;
  background-color: color(light);
  outline: none;
}

.popupScrollable {
  justify-content: start;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  @include size(24px);
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
