@import '../../styles/utils';

.loader {
  width: 12em;
  height: 12em;
  position: relative;
  text-indent: -9999em;
  border-width: 1.5em;
  border-style: solid;
  transform: translateZ(0);
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
