@import '../../styles/utils';

.bottomText {
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  a {
    display: inline-block;
    color: color(primary);

    &:hover,
    &:focus {
      color: lighten(color(primary), 8%);
    }
  }
}

.popup {
  padding-bottom: 24px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.form {
  margin-top: 29px;
}

.fields {
  & > * {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.button {
  margin-top: 30px;
  width: 100%;
}

.error {
  margin-top: 25px;
  color: color(error);
}
